import {
    CLEAN_SEARCH_LIST_RESPONSE,
    SEARCH_DEAL_COMPLETE,
    SEARCH_DEALS_BY_MONTH_COMPLETE,
    SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE,
    SEARCH_TEXT_IN_DEAL,
} from '../../consts/actionTypes';
import { mapData } from '../../consts/mapSearchByDeal';
const initialState = {
    listResponse: null,
    searchByDealResponse: null,
    dataTable: {
        columns: [
            { title: 'Deal Recap', field: 'FIWI' },
            { title: 'Contract Date', field: 'DASO' },
            { title: 'Costumer Name', field: 'BUNA' },
            { title: 'Vehicle', field: 'VESTNO' },
            { title: 'Maker', field: 'MAVE' },
            { title: 'Model', field: 'MOVE' },
            { title: '#', field: 'SANO1' },
            { title: 'Assocaite', field: 'SANA' },
        ],
        data: [],
    },
    listOrderData: [],
    originalList: [],
};

const orderData = (objResponse) => {
    const returnNumber = (x) => {
        if (x) {
            let y = x.replace(',', '');
            return Number(y);
        } else {
            return 0;
        }
    };
    const putInBaseObject = (key, newTitle, newDescription, newSale, newCoste) => {
        if (typeof newDescription !== 'undefined') {
            if (newSale !== 0 || newCoste !== 0) {
                baseObjDeal = {
                    ...baseObjDeal,
                    grossProfitAnalysis: {
                        ...baseObjDeal.grossProfitAnalysis,
                        [key]: [
                            ...baseObjDeal.grossProfitAnalysis[key],
                            {
                                Title: newTitle,
                                Description: newDescription,
                                Sale: newSale,
                                Cost: newCoste,
                                Gross: newSale - newCoste,
                            },
                        ],
                    },
                };
            }
        }
    };
    const putInBaseObjectOneField = (key, newTitle, newDescription, keyObjt, valueObject) => {
        if (valueObject === 0) {
            return;
        }
        let newObj = {
            Title: '',
            Description: '',
            Sale: 0,
            Cost: 0,
            Gross: 0,
        };
        if (typeof newDescription !== 'undefined') {
            baseObjDeal = {
                ...baseObjDeal,
                grossProfitAnalysis: {
                    ...baseObjDeal.grossProfitAnalysis,
                    [key]: [
                        ...baseObjDeal.grossProfitAnalysis[key],
                        {
                            ...newObj,
                            Title: newTitle,
                            Description: newDescription,
                            [keyObjt]: valueObject,
                        },
                    ],
                },
            };
        }
    };
    const totalInGrossProfitAnalysis = (keyNameInObjt) => {
        let tempArrayObjSubTotal = [...baseObjDeal.grossProfitAnalysis[keyNameInObjt]];
        let tempTotalSale = { ...baseObjDeal.grossProfitAnalysis.totalsale };
        let tempSubTotal = {
            Sale: 0,
            Cost: 0,
            Gross: 0,
        };
        let newTotalKey = `total${keyNameInObjt}`;
        for (let obj of tempArrayObjSubTotal) {
            tempSubTotal = {
                ...tempSubTotal,
                Sale: tempSubTotal.Sale + obj.Sale,
                Cost: tempSubTotal.Cost + obj.Cost,
                Gross: tempSubTotal.Gross + obj.Gross,
            };
        }
        tempTotalSale = {
            ...tempTotalSale,
            Sale: tempSubTotal.Sale + tempTotalSale.Sale,
            Cost: tempSubTotal.Cost + tempTotalSale.Cost,
            Gross: tempSubTotal.Gross + tempTotalSale.Gross,
        };
        baseObjDeal = {
            ...baseObjDeal,
            grossProfitAnalysis: {
                ...baseObjDeal.grossProfitAnalysis,
                [newTotalKey]: { ...baseObjDeal.grossProfitAnalysis[newTotalKey], ...tempSubTotal },
                totalsale: { ...tempTotalSale },
            },
        };
    };

    let baseObjDeal = { ...mapData };
    let newObjGrossProfitAnalysis = {
        Title: '',
        description: '',
        Sale: 0,
        Cost: 0,
        Gross: 0,
    };

    for (const arrayParent in baseObjDeal) {
        if (arrayParent === 'grossProfitAnalysis' || arrayParent === 'commissions') {
            break;
        }
        let tempArray = [...baseObjDeal[arrayParent]];
        let newArray = [];
        for (const objChild of tempArray) {
            let tempObj = {};
            if (objResponse.hasOwnProperty(objChild.code)) {
                tempObj = { ...objChild, value: objResponse[objChild.code] };
                newArray = [...newArray, tempObj];
            }
        }
        baseObjDeal = { ...baseObjDeal, [arrayParent]: newArray };
    }

    if (objResponse.DETY == 'P') {
        putInBaseObject(
            'front',
            'Vehicle',
            objResponse.VESTNO,
            returnNumber(objResponse.CAPR),
            returnNumber(objResponse.VECOGP) - returnNumber(objResponse.ADRE)
        );
        putInBaseObject(
            'front',
            'Trade 1',
            objResponse.TR1STNO,
            returnNumber(objResponse.TR1AC),
            returnNumber(objResponse.TR1GRVA)
        );
        putInBaseObject(
            'front',
            'Trade 2',
            objResponse.TR2STNO,
            returnNumber(objResponse.TR2AC),
            returnNumber(objResponse.TR2GRVA)
        );
        putInBaseObject(
            'back',
            'Service Contract',
            objResponse.MBINNA,
            returnNumber(objResponse.MBINFE),
            returnNumber(objResponse.MBCo)
        );
        putInBaseObject(
            'back',
            'Insurance',
            ' NSD GAP',
            returnNumber(objResponse.IN2FE),
            returnNumber(objResponse.In2Co)
        );
        putInBaseObject(
            'back',
            'Insurance',
            objResponse.IN3NA,
            returnNumber(objResponse.IN3FE),
            returnNumber(objResponse.In3Co)
        );
    } else {
        putInBaseObject(
            'front',
            'Vehicle',
            objResponse.VESTNO,
            returnNumber(objResponse.CACO),
            returnNumber(objResponse.VECOGP) - returnNumber(objResponse.ADRE)
        );
        putInBaseObject(
            'front',
            'Trade 1',
            objResponse.TR1STNO,
            returnNumber(objResponse.ACOFTR1),
            returnNumber(objResponse.TR1AL)
        );
        putInBaseObject(
            'front',
            'Trade 2',
            objResponse.TR2STNO,
            returnNumber(objResponse.ACOFTR1),
            returnNumber(objResponse.TR2AL)
        );
        putInBaseObjectOneField(
            'back',
            'Service Contract',
            objResponse.MBINNA,
            'Gross',
            returnNumber(objResponse.MBDECO)
        );
        putInBaseObject('back', 'Fess(Back)', 'ACQ FEE', 995, 695);
        if (objResponse.SUDLCO) {
            let tempTotalSaleSurplesh = { ...baseObjDeal.grossProfitAnalysis.totalsale };
            baseObjDeal = {
                ...baseObjDeal,
                grossProfitAnalysis: {
                    ...baseObjDeal.grossProfitAnalysis,
                    surplush: {
                        Title: 'Surplush Cash',
                        description: '',
                        Sale: returnNumber(objResponse.SUDLCO),
                        Cost: 0,
                        Gross: returnNumber(objResponse.SUDLCO),
                    },
                    totalsale: {
                        ...tempTotalSaleSurplesh,
                        Sale: tempTotalSaleSurplesh.Sale + returnNumber(objResponse.SUDLCO),
                        Gross: tempTotalSaleSurplesh.Gross + returnNumber(objResponse.SUDLCO),
                    },
                },
            };
        }
    }

    putInBaseObject('front', 'Cost Adjustment(front)', '', 0, returnNumber(objResponse.ADRE));
    putInBaseObject(
        'front',
        'We Owe Front(front)',
        'Donation Fee',
        returnNumber(objResponse.DFSA),
        returnNumber(objResponse.DFCO)
    );
    putInBaseObject(
        'front',
        'We Owe Front(front)',
        'Warranty fees',
        0,
        returnNumber(objResponse.ARCO) //validar si es el campo correcto
    );
    putInBaseObject(
        'front',
        'Fees (front)',
        objResponse.DETY == 'P' ? 'DEALER FEE' : 'DOC FEE',
        returnNumber(objResponse.FE1AM),
        0
    );
    putInBaseObject(
        'front',
        'Fees (front)',
        objResponse.FE4NA,
        returnNumber(objResponse.FE4AM),
        returnNumber(objResponse.FeOp4Co)
    );
    putInBaseObject(
        'back',
        'Finance Reserve',
        objResponse.FICONA,
        returnNumber(objResponse.DLFIRE),
        0
    );
    putInBaseObject(
        'back',
        'We Owe (Back)',
        'TRACEABLE THEFT PROGRAM',
        returnNumber(objResponse.BOSA),
        returnNumber(objResponse.BOCO)
    );
    putInBaseObject(
        'back',
        'We Owe (Back)',
        'KEY REMPLACEMENT',
        returnNumber(objResponse.M2SALE),
        returnNumber(objResponse.M2COST)
    );
    putInBaseObject(
        'back',
        'We Owe (Back)',
        'RSA/ADR',
        returnNumber(objResponse.A5SA),
        returnNumber(objResponse.A5CO)
    );
    putInBaseObject(
        'back',
        'We Owe (Back)',
        'EXCESS WEAR AND TEAR',
        returnNumber(objResponse.M5SALE),
        returnNumber(objResponse.M5COST)
    );
    putInBaseObject(
        'back',
        '',
        'Accident & Health',
        returnNumber(objResponse.AHPRE),
        returnNumber(objResponse.AHCo)
    );
    putInBaseObject(
        'back',
        '',
        'Alloy Wheel',
        returnNumber(objResponse.MALLOYWSALE),
        returnNumber(objResponse.MALLOYWCOST)
    );
    putInBaseObject(
        'back',
        'We Owe (Back)',
        'VALUE SHIELD',
        returnNumber(objResponse.MRSA),
        returnNumber(objResponse.MRCO)
    );
    putInBaseObject(
        'back',
        '',
        'Vehicle Replacement',
        returnNumber(objResponse.MHAISALE),
        returnNumber(objResponse.MHAICOST)
    );
    putInBaseObject(
        'back',
        '',
        'Vehicle Replacement',
        returnNumber(objResponse.MRHSALE),
        returnNumber(objResponse.RHCOST)
    );
    putInBaseObject(
        'back',
        '',
        'NAS PAINT AND DENT',
        returnNumber(objResponse.MNASPAINTSALE),
        returnNumber(objResponse.MNASPAINTCOST)
    );
    putInBaseObject(
        'back',
        '',
        'NAS ADR / NITRO',
        returnNumber(objResponse.MA1SALE),
        returnNumber(objResponse.MWEO13COST)
    );
    putInBaseObjectOneField(
        'other',
        'Other Fees Charged In Deal	',
        objResponse.FE3NA,
        'Sale',
        returnNumber(objResponse.FE3AM)
    );
    putInBaseObjectOneField(
        'other',
        'Other Fees Charged In Deal	',
        objResponse.FE6NA,
        'Sale',
        returnNumber(objResponse.FE6AM)
    );
    putInBaseObjectOneField(
        'other',
        'Other Fees Charged In Deal	',
        objResponse.FE5NA,
        'Sale',
        returnNumber(objResponse.FE5AM)
    );
    putInBaseObjectOneField(
        'other',
        'Other Fees Charged In Deal	',
        objResponse.FE10NA,
        'Sale',
        returnNumber(objResponse.FE10AM)
    );
    putInBaseObjectOneField(
        'other',
        'Other Fees Charged In Deal	',
        objResponse.FE7NA,
        'Sale',
        returnNumber(objResponse.FE7AM)
    );
    putInBaseObjectOneField(
        'taxes',
        'Taxes Charged In Deal',
        objResponse.TA1NA,
        'Sale',
        returnNumber(objResponse.TA1AM)
    );
    totalInGrossProfitAnalysis('front');
    totalInGrossProfitAnalysis('back');
    baseObjDeal = {
        ...baseObjDeal,
        commissions: [
            {
                Role: 'SP1',
                Emple: objResponse.SANO1,
                Name: objResponse.SANA,
                Comm: objResponse.DETY == 'P' ? objResponse.NEUS : 'Lease',
                Base: objResponse.TOCO1,
                Total: objResponse.TOCO1,
                keys: {
                    emple: 'SANO1',
                    name: 'SANA',
                    base: 'TOCO1',
                    total: 'TOCO1',
                },
            },
            {
                Role: 'SP2',
                Emple: objResponse.SANO2 ? objResponse.SANO2 : '',
                Name: objResponse.SA2 ? objResponse.SA2 : '',
                Comm: '',
                Base: objResponse.TOCO2 ? objResponse.TOCO2 : '',
                Total: objResponse.TOCO2 ? objResponse.TOCO2 : '',
                keys: {
                    emple: 'SANO2',
                    name: 'SA2',
                    base: 'TOCO2',
                    total: 'TOCO2',
                },
            },
            {
                Role: 'FIM',
                Emple: objResponse.FIMA ? objResponse.FIMA : '',
                Name: objResponse.FIMAN ? objResponse.FIMAN : '',
                Comm: '',
                Base: '',
                Total: '',
                keys: {
                    emple: 'FIMA',
                    name: 'FIMAN',
                },
            },
            {
                Role: 'TOTAL',
                Emple: '',
                Name: '',
                Comm: '',
                Base: '',
                Total: objResponse.ToAlCo,
                keys: {
                    total: 'ToAlCo',
                },
            },
        ],
    };

    return baseObjDeal;
};
export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAN_SEARCH_LIST_RESPONSE:
            return {
                ...state,
                listResponse: null,
                searchByDealResponse: null,
                listOrderData: [],
                dataTable: initialState.dataTable,
                originalList: [],
            };
        case SEARCH_DEAL_COMPLETE:
            return {
                ...state,
                listResponse: orderData(action.results.data[0]),
                searchByDealResponse: action.results.data[0],
                originalList: action.results.data[0],
            };
        case SEARCH_TEXT_IN_DEAL:
            const keyChange = action.keyText;
            const newValue = action.valueText;
            let orignalListState = state.originalList;

            orignalListState = { ...orignalListState, [keyChange]: newValue };

            return {
                ...state,
                listResponse: orderData(orignalListState),
                originalList: orignalListState,
            };
        case SEARCH_DEALS_BY_MONTH_COMPLETE:
            let dataOrderTemp = [];
            for (const objDataOrigin of action.results.data) {
                dataOrderTemp = [...dataOrderTemp, orderData(objDataOrigin)];
            }

            return {
                ...state,
                dataTable: { ...state.dataTable, data: [...action.results.data] },
                listResponse: [...action.results.data],
                listOrderData: [...dataOrderTemp],
            };
        case SEARCH_SUBDEAL_BY_ASSOCIATE_COMPLETE:
            const response = action.results.data[0];
            const createArrayObjct = (keyName) => {
                let arrayObject = [];
                let index = 1;
                while (true) {
                    let key = `${keyName}${index}`;
                    if (response.hasOwnProperty(`${key}D`)) {
                        let object = {
                            description: response[`${key}D`],
                            coste: response[`${key}C`],
                            price: response[`${key}P`],
                        };
                        arrayObject = [...arrayObject, object];
                        index++;
                    } else {
                        break;
                    }
                }
                return arrayObject;
            };
            const createBasicObject = () => {
                let newObj = {};
                for (let i in response) {
                    if (i.length < 6 || i.length > 8) {
                        newObj = { ...newObj, [i]: response[i] };
                    }
                }
                return newObj;
            };
            const data = {
                basic: createBasicObject(),
                front: createArrayObjct('FRONT'),
                back: createArrayObjct('BACK'),
            };

            return {
                ...state,
                listResponse: { ...data },
            };

        default:
            return { ...state };
    }
}
