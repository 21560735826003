import React, { useState } from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { changeTextDealAction, editDealAction } from '../../../../redux/actions/searchAction';
import { isLoadingAction } from '../../../../redux/actions/utilitiesAction';
import { dinamicSelector, listResponseSelector } from '../../../../redux/selectors';
import PropTypes from 'prop-types';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Typography,
    IconButton,
    TextField,
    InputBase,
} from '@material-ui/core';
import { SimpleProgress } from '../../components';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import Edit from '@material-ui/icons/Edit';
import { Save, Cancel } from '@material-ui/icons';

const Index = (props) => {
    const { deal } = props;
    const { orderData } = props;
    const dispatch = useDispatch();
    const data = useSelector((state) => listResponseSelector(state, 'searchReducer'));
    const searchByDealData = useSelector((state) =>
        dinamicSelector(state, 'searchReducer', 'searchByDealResponse')
    );

    const [editable, setEditable] = useState(false);
    const [grossEditable, setGrossEditable] = useState(false);
    const [grossData, setGrossData] = useState({});
    const handleEditable = (bol) => {
        setEditable(bol);
    };

    const getGrossDataKey = (grossData, columnHeader) => {
        if (columnHeader == 'Cost' || columnHeader === 'Gross' || columnHeader === 'Sale')
            return grossData?.Title + grossData?.Description + columnHeader;

        return '';
    };

    const handleGrossData = (key, value) => {
        if (key) setGrossData({ ...grossData, [key]: value });
    };

    const handleGrossEdit = (bol) => {
        setGrossEditable(bol);
    };
    const createNameHeader = () => {
        let array = [];
        for (const key in data.grossProfitAnalysis.front[0]) {
            if (key !== 'keys') {
                array = [...array, key];
            }
        }
        return array;
    };
    const createNameHeaderCommissions = () => {
        let array = [];
        for (const key in data.commissions[0]) {
            if (key !== 'keys') {
                array = [...array, key];
            }
        }
        return array;
    };
    const onChangeText = (e) => {
        dispatch(changeTextDealAction(e.target.name, e.target.value));
    };
    const onEdit = () => {
        setEditable(false);
        dispatch(isLoadingAction(true));
        dispatch(editDealAction(deal));
    };
    const classes = useStyles();
    return (
        <div className={classes.divPrint}>
            <div className={classes.titleButton}>
                <Typography variant="h4" style={{ marginBottom: '0px' }} gutterBottom>
                    Deal Info
                </Typography>
                <IconButton
                    size="small"
                    onClick={() => (editable ? onEdit() : handleEditable(true))}
                >
                    {editable ? <DoneIcon fontSize="inherit" /> : <EditIcon fontSize="inherit" />}
                </IconButton>
                {editable && (
                    <IconButton size="small" onClick={() => handleEditable(false)}>
                        <CancelIcon fontSize="inherit" />
                    </IconButton>
                )}
            </div>
            <Grid container spacing={3}>
                <Grid item className={classes.field} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Store:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.STORE}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} sm={6} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Deal#:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.FIWI}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} sm={6} xs={12}>
                    {editable ? (
                        <div className={classes.contentFit}>
                            <TextField
                                label={'Contract Date:'}
                                name={'Contract Date:'}
                                size="small"
                                value={searchByDealData.DASO}
                                onChange={onChangeText}
                                variant="outlined"
                                className={classes.textField}
                            />
                        </div>
                    ) : (
                        <div className={classes.contentFit}>
                            <Typography variant="h6" gutterBottom>
                                {'Contract Date:'}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {searchByDealData.DASO}
                            </Typography>
                        </div>
                    )}
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Deal Type:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.DETY}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    {editable ? (
                        <div className={classes.contentFit}>
                            <TextField
                                label={'Sales Person 1:'}
                                name={'Sales Person 1:'}
                                size="small"
                                value={searchByDealData.SANO1}
                                onChange={onChangeText}
                                variant="outlined"
                                className={classes.textField}
                            />
                        </div>
                    ) : (
                        <div className={classes.contentFit}>
                            <Typography variant="h6" gutterBottom>
                                {'Sales Person 1:'}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {searchByDealData.SANO1}
                            </Typography>
                        </div>
                    )}
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    {editable ? (
                        <div className={classes.contentFit}>
                            <TextField
                                label={'Sales Person 2:'}
                                name={'Sales Person 2:'}
                                size="small"
                                value={searchByDealData.SANO2 ?? 0}
                                onChange={onChangeText}
                                variant="outlined"
                                className={classes.textField}
                            />
                        </div>
                    ) : (
                        <div className={classes.contentFit}>
                            <Typography variant="h6" gutterBottom>
                                {'Sales Person 2:'}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {searchByDealData.SANO2}
                            </Typography>
                        </div>
                    )}
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    {editable ? (
                        <div className={classes.contentFit}>
                            <TextField
                                label={'Finance Manager:'}
                                name={'Finance Manager:'}
                                size="small"
                                value={searchByDealData.FIMA}
                                onChange={onChangeText}
                                variant="outlined"
                                className={classes.textField}
                            />
                        </div>
                    ) : (
                        <div className={classes.contentFit}>
                            <Typography variant="h6" gutterBottom>
                                {'Finance Manager:'}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {searchByDealData.FIMA}
                            </Typography>
                        </div>
                    )}
                </Grid>
            </Grid>
            <Typography
                variant="h4"
                style={{ marginTop: '16px', marginBottom: '16px' }}
                gutterBottom
            >
                Customer Info
            </Typography>
            <Grid container spacing={3}>
                <Grid item className={classes.field} sm={6} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Name:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.BUNA}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} sm={6} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Home Phone:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.BUPH1}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} sm={6} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Address:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.BUYSTR}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} sm={6} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Work Phone:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.BUPH2}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <Typography
                variant="h4"
                style={{ marginTop: '16px', marginBottom: '16px' }}
                gutterBottom
            >
                Car Info
            </Typography>
            <Grid container spacing={3}>
                <Grid item className={classes.field} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Stock#:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.VESTNO}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Make::'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.MAVE}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Model:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.MOVE}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Year:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.YEVE}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'VIN#:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.SENOVE}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xs={12}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Finance Institution:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.FICONA}
                        </Typography>
                    </div>
                </Grid>
                <Grid item className={classes.field} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'Hold Back:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {searchByDealData.HB}
                        </Typography>
                    </div>
                </Grid>
                {/* <Grid item className={classes.field} xl={4} lg={4} md={4} sm={4} xs={4}>
                    <div className={classes.contentFit}>
                        <Typography variant="h6" gutterBottom>
                            {'DAP:'}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                        {Number(searchByDealData.DAP).toFixed(2)}
                        </Typography>
                    </div>
                </Grid> */}
            </Grid>
            <Typography variant="h4" style={{ marginTop: '10px' }} gutterBottom>
                Sales Data
            </Typography>
            <TableContainer>
                <Table stickyHeader size="small" className={classes.table} align="center">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                size="medium"
                                padding="none"
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Description
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Sale
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Cost
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Gross
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {new Array(3).fill(0).map((_, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        FRONT1D
                                    </TableCell>

                                    <TableCell
                                        className={classes.cell}
                                    >
                                        $485.00
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        $10.00
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                        style={{ padding: 0 }}
                                    >
                                        $495.00
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell className={`${classes.cell} ${classes.bold}`}>
                                Total Sale
                            </TableCell>

                            <TableCell
                                className={classes.cell}
                            >
                                $485.00
                            </TableCell>
                            <TableCell
                                className={classes.cell}
                            >
                                $10.00
                            </TableCell>
                            <TableCell
                                className={classes.cell}
                                style={{ padding: 0 }}
                            >
                                $495.00
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h4" gutterBottom>
                Finance Data
            </Typography>
            <TableContainer>
                <Table stickyHeader size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                size="medium"
                                padding="none"
                                align="right
                                "
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Description
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                align="right
                                "
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Sale
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                align="right
                                "
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Cost
                            </TableCell>
                            <TableCell
                                size="medium"
                                padding="none"
                                align="right
                                "
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Gross
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {new Array(3).fill(0).map((_, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        FRONT1D
                                    </TableCell>

                                    <TableCell
                                        className={classes.cell}
                                    >
                                        $485.00
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        $10.00
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                        style={{ padding: 0 }}
                                    >
                                        $495.00
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        <TableRow>
                            <TableCell className={`${classes.cell} ${classes.bold}`}>
                                Total Finance
                            </TableCell>

                            <TableCell
                                className={classes.cell}
                            >
                                $485.00
                            </TableCell>
                            <TableCell
                                className={classes.cell}
                            >
                                $10.00
                            </TableCell>
                            <TableCell
                                className={classes.cell}
                                style={{ padding: 0 }}
                            >
                                $495.00
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                className={`${classes.cell} ${classes.bold}`}
                            >
                                Total Sale + Total Finance
                            </TableCell>
                            <TableCell
                                colSpan={4}
                                className={classes.cell}
                                style={{ padding: 0 }}
                            >
                                $2815.00
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

Index.propTypes = {
    deal: PropTypes.object.isRequired,
};
Index.defaultProps = {};

export default Index;
